<script setup>
const route = useRoute()
const { generateBreadcrumbs, generateMeta } = useMetaUtils()
const { getPageForRoute } = useApiPage()

const { data: page } = await useAsyncData(
  `page-${route?.path}`,
  getPageForRoute,
)

if (!page.value) {
  navigateTo({ name: 'not-found' })
}

useSeoMeta(generateMeta(page.value))
</script>

<template>
  <BaseContent
    v-if="page"
    :content="page?.content"
    :breadcrumbs="generateBreadcrumbs(page)"
  />
</template>
